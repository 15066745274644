<template>
    <el-form :rules="rules" :model="localModel" label-position="left" label-width="110px" ref="form">
        <el-form-item label="На дату" prop="date">
          <el-date-picker
            v-model="localModel.date"
            type="date"
            format="dd.MM.yyyy"
            style="width: 100%"
            placeholder="Выберите дату">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Инженеры" prop="executors">
            <!--<a-select
              name="executors"
              :registry-id="963"
              :attribute-id="1400"
              :block="true"
              :multiple="true"
              v-model="localModel.executors"
            ></a-select>-->
          <el-select
              v-model="localModel.executors"
              :clearable="true"
              filterable
              collapse-tags
              :multiple="true"
          >
            <el-option
                v-for="(item, index) in executors"
                :key="index"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-button type="text" @click="selectAll">Выбрать всех</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
  name: 'show-current-location',
  provide: {
    isEditor: () => false
  },
  props: {
    model: {
      type: Object
    },
    roleId: Number,
    userId: Number
  },
  data () {
    return {
      executors: [],
      localModel: this.model,
      rules: {
        date: [
          { required: true, message: 'Введите дату', trigger: 'blur' }
        ],
        executors: [
          { required: true, message: 'Выберите исполнителей', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    isValid () {
      return this.$refs.form.validate()
    },
    async loadExecutors () {
      if (this.roleId === 113) {
        const { data } = await this.$http.get(`${this.$config.api}/registryservice/registry/963/card/${this.userId}`)
        this.executors = JSON.parse(data['attr_2720_'])
      } else {
        const { data } = await this.$http.get(`${this.$config.api}/registryservice/xref/?registry_id=963&attribute_id=1400`)
        this.executors = data
      }
    },
    selectAll () {
      this.localModel.executors = this.executors.map(item => item.id)
    }
  },
  mounted () {
    this.loadExecutors()
  },
  watch: {
    localModel: {
      handler (value) {
        this.model = value
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
